import * as React from "react"
import Layout from "../components/layout";
import Abschnitt from "../components/abschnitt";
import Header from "../components/header/header";
import {graphql} from "gatsby";

const Tools = (location) => {
    return (
        <Layout>
            <Abschnitt>
                <Header location={location}/>
            </Abschnitt>

            <Abschnitt>
                <h1>Tools</h1>
            </Abschnitt>

            <Abschnitt>

            </Abschnitt>
        </Layout>
    )
}

export default Tools

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
